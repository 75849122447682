import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved11 from "assets/images/curved-images/curved11.jpg";

import AuthApi from "../../../../api/auth";

function ChangePassword() {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setError("");

    // Check if new password and repeat new password match
    if (formData.newPassword !== formData.repeatNewPassword) {
      setError("New passwords do not match");
      return;
    }

    setLoading(true);
    AuthApi.Change({
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword
    }).then(response => {
      setLoading(false);
      if (response) {
        navigate("/dashboards/default");
      } else {
        setError("Password change failed. Please try again.");
      }
    }).catch(error => {
      setLoading(false);
      setError("Password minimum length: 8 characters, Contains at least 1 number, Contains at least 1 special character, Contains at least 1 uppercase letter, Contains at least 1 lowercase letter.");
      console.error("Error during password change:", error);
    });
  };

  return (
    <CoverLayout
      title="Change Password"
      image={curved11}
      top={25}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} lineHeight={1.25} position="relative">
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Old Password
            </SoftTypography>
          </SoftBox>
          <OutlinedInput
            type={showOldPassword ? "text" : "password"}
            name="oldPassword"
            value={formData.oldPassword}
            onChange={handleChange}
            fullWidth
            placeholder="Old Password"
            style={{ paddingRight: 40 }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowOldPassword(!showOldPassword)}
            edge="end"
            style={{
              position: 'absolute',
              right: 10,
              top: '70%',
              transform: 'translateY(-50%)'
            }}
          >
            {showOldPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25} position="relative">
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              New Password
            </SoftTypography>
          </SoftBox>
          <OutlinedInput
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            fullWidth
            placeholder="New Password"
            style={{ paddingRight: 40 }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowNewPassword(!showNewPassword)}
            edge="end"
            style={{
              position: 'absolute',
              right: 10,
              top: '70%',
              transform: 'translateY(-50%)'
            }}
          >
            {showNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25} position="relative">
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Repeat New Password
            </SoftTypography>
          </SoftBox>
          <OutlinedInput
            type={showRepeatNewPassword ? "text" : "password"}
            name="repeatNewPassword"
            value={formData.repeatNewPassword}
            onChange={handleChange}
            fullWidth
            placeholder="Repeat New Password"
            style={{ paddingRight: 40 }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
            edge="end"
            style={{
              position: 'absolute',
              right: 10,
              top: '70%',
              transform: 'translateY(-50%)'
            }}
          >
            {showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </SoftBox>
        <SoftBox mt={2} mb={2} textAlign="center">
          {error && (
            <SoftAlert color="error">{error}</SoftAlert>
          )}
        </SoftBox>
        <SoftBox mt={4} mb={1} textAlign="center">
          <SoftButton variant="gradient" color="info" onClick={handleSubmit} fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ChangePassword;
