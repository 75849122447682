import React, { useEffect, useState, useCallback } from 'react';
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import FilesApi from "../../../../../api/files";
import AuthApi from "../../../../../api/auth";

// Define facility names
const facilities = ['WRC', 'NSR', 'TLR', 'THI', 'SERC', 'PCD', 'DIFAD', 'RRC'];

function Documents() {
  const [files, setFiles] = useState([]);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [clinic, setClinic] = useState({ value: "", label: "" });

  useEffect(() => {
    if (clinic.value) {
      getFiles();
    }
  }, [clinic, userRole]);

  useEffect(() => {
    bootstrap();
  }, []);

  const handleClinicChange = useCallback(async (selectedClinic) => {
    setClinic(selectedClinic);
  }, []);

  const bootstrap = useCallback(async () => {
    const userFinded = await AuthApi.Me();
    setUser(userFinded.data);
    setUserRole(userFinded.data.role);
    if (userFinded.data.role === "client") {
      setClinic({ value: userFinded.data.facility.id, label: userFinded.data.facility.Facility });
    }
  }, []);

  const getFiles = useCallback(async () => {
    const filesFinded = await FilesApi.getFilesList(
      userRole === 'admin' ? `/by-clinic?clinic=${clinic.value}` : ''
    );

    if (filesFinded.data) {
      setFiles(filesFinded.data.map((currentFile) => ({
        id: currentFile.id, 
        name: currentFile.key,
        dateUploaded: new Date(currentFile.createdAt).toLocaleString(),
        url: currentFile.link, // Placeholder URL
        checked: false,
      })));
    }
  }, [clinic, userRole]);

  const handleCheckboxToggle = (index) => {
    setFiles(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles[index].checked = !newFiles[index].checked;
      return newFiles;
    });
  };

  const handleDelete = async (file) => {
    console.log("file_key-------")
    console.log(file.name)
    const confirmDelete = window.confirm(`Are you sure you want to delete ${file.name}?`);
    if (confirmDelete) {
      try {
        await FilesApi.deleteFile(file.name);
        // Refresh the file list after deletion
        getFiles();
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete the file. Please try again.");
      }
      
    }
  };

  return (
    <DashboardLayout sx={{ position: 'relative', height: '100vh' }}>
      <DashboardNavbar />
      <SoftBox mt={1} mb={1}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <SoftBox mt={4}>
              <SoftTypography variant="h5" fontWeight="bold">
                Uploaded Documents
              </SoftTypography>
              {user && user.role === "admin" &&
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftSelect title="Clinic"
                    value={clinic} onChange={e => handleClinicChange(e)}
                    placeholder="Clinic"
                    options={[
                      { value: "25", label: "WRC" },
                      { value: "26", label: "NSR" },
                      { value: "27", label: "TLR" },
                      { value: "28", label: "THI" },
                      { value: "29", label: "SERC" },
                      { value: "30", label: "PCD" },
                      { value: "31", label: "LH" },
                      { value: "36", label: "DIFAD" },
                      { value: "37", label: "RRC" }
                    ]}
                  />
                </SoftBox>
              }
              {clinic.value && (
                <div key={clinic.label}>
                  <SoftTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
                    {clinic.label}
                  </SoftTypography>
                  <table style={{ width: '100%', marginTop: '16px', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>File Name</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Date Uploaded</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Download</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Status</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((file, index) => (
                        <tr key={index}>
                          <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{file.name}</td>
                          <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{file.dateUploaded}</td>
                          <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                            <SoftButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={() => window.open(file.url, '_blank')}
                            >
                              Download
                            </SoftButton>
                          </td>
                          <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                            <div onClick={() => handleCheckboxToggle(index)} style={{ cursor: 'pointer' }}>
                              {file.checked ? <CheckCircleIcon color="success" /> : <CheckCircleOutlineIcon color="success" />}
                            </div>
                          </td>
                          <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                            <SoftButton
                              variant="gradient"
                              color="error"
                              size="small"
                              onClick={() => handleDelete(file)}
                            >
                              <DeleteIcon />
                            </SoftButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Documents;
