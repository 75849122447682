import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Images
import welcome from "assets/images/welcome_taka.png";
import mobileWork from "assets/images/welcome_taka_mobile.png";


function SmartHome() {
  const { width } = useWindowSize();
  const isMobile = width <= 600; 

  return (
    <DashboardLayout sx={{  display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <DashboardNavbar />
      <SoftBox component="main" sx={{ flexGrow: 1, pt: 3, pb: 3 }}>
        <SoftBox mb={3}>
          <Grid>
            <Grid item xs={12} xl={12}>
              <Card >
                <SoftBox p={2} mt={1} mb={0} ml={0} width="100%" height="100vh">
                  <img src={isMobile ? mobileWork : welcome} alt="welcome" style={{ borderRadius: "lg", width: "100%", height: "auto", objectFit: "cover" }} />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={0}>
          <Grid container spacing={3}>
          </Grid>
        </SoftBox>
      </SoftBox>
      <SoftBox sx={{ position: 'relative',
          textAlign: 'center',
          width: '100%', 
          py: 2,
          }}
           >
        <SoftBox my={6} width="100%" >
          <Divider />
        </SoftBox>
        <Footer />
      </SoftBox>      
    </DashboardLayout>
  );
}

export default SmartHome
