import { axios } from "./index";

class PatientApi {
  static getpatientsList = (curFacility) => {
    return axios.get(`${base}/${curFacility}`);
  };
}

let base = "patient";

export default PatientApi;
