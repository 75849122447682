import React, { useState, useCallback, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FilesApi from "../../../../../api/files";

function Upload() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [clinic, setClinic] = useState();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = useCallback(async(e) => {
    console.log(file, clinic)
    // if (file && (clinic && clinic.value)) {
    if (file) {
      setUploadStatus("File uploading...");
      
    const fileForm = new FormData();
    fileForm.append('file', file);
    // fileForm.append('Primary_Key', clinic.value);
    console.log(fileForm);
    const uploaded = await FilesApi.uploadFile(fileForm);
      console.log("Uploaded!");
      console.log(uploaded);
      if(uploaded.data && uploaded.data.id) {
        setUploadStatus(`File uploaded successfully => ${uploaded.data.link}`);
      }
    } else {
      setUploadStatus("Please select a file to upload");
    }
  }, [file, setUploadStatus, clinic]);
  
  useEffect(() => {
    console.log(clinic);
  }, [clinic]);

  // const handleClinicChange = useCallback((selectedClinic) => {
  //   setClinic(() => selectedClinic);
  // }, [clinic, setClinic]);

  return (
    <DashboardLayout sx={{ position: 'relative', height: '100vh' }}>
      <DashboardNavbar />
      <SoftBox mt={15} mb={1}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <SoftBox mb={2} textAlign="center">
              <SoftTypography variant="h4" fontWeight="bold">
                Upload Document
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={2} textAlign="center">
            {/* <SoftBox mb={2} lineHeight={1.25}>
            <SoftSelect title="Clinic"
              value={clinic} onChange={e => handleClinicChange(e)}
              placeholder="Clinic"
              options={[
                { value: "25", label: "WRC" },
                { value: "26", label: "NSR" },
                { value: "27", label: "TLR" },
                { value: "28", label: "THI" },
                { value: "29", label: "SERC" },
                { value: "30", label: "PCD" },
                { value: "31", label: "LH" },
                { value: "36", label: "DIFAD" },
              ]}
            />
          </SoftBox> */}
              <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="file-upload">
                <SoftButton variant="gradient" color="white" component="span">
                  Choose File
                </SoftButton>
              </label>
            </SoftBox>
            <SoftBox textAlign="center">
              <SoftButton variant="gradient" color="info" onClick={handleFileUpload}>
                Upload
              </SoftButton>
            </SoftBox>
            {uploadStatus && (
              <SoftBox mt={2} textAlign="center">
                <SoftTypography variant="h6" color="success">
                  {uploadStatus}
                </SoftTypography>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Upload;
