import { axios } from "./index";

class InsuranceApi {
  static getByInsurance = (insurance) => {
    return axios.get(`${base}/${insurance}`);
  };

  static getAvailableInsurances = () => {
    return axios.get(`${base}/available`);
  };
}

let base = "insurance";

export default InsuranceApi;
