import { axios } from "./index";

class PrefixApi {
  static getByPrefix = (prefix) => {
    return axios.get(`${base}/${prefix}`);
  };
}

let base = "prefix";

export default PrefixApi;
