import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from './auth-context/auth.context';
import { useNavigate, Outlet } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  let { user, setUser } = useAuth();
  const [ userSigned, setUserSigned ] = useState(false);

  useEffect(() => {
    if(user && user.cognito_id) {
      setUserSigned(() => true);
    } else {
      setUserSigned(() => false);
    }
    console.log("------USER SIGNED---");
    console.log(userSigned);
    console.log(user);
  }, [user, userSigned, setUserSigned]);

  const redirect = useCallback(async() => {
    await setUser(() => null);
    await navigate("/authentication/sign-in")
    }, [user, setUser]);

  if(userSigned) {
    return <Outlet />;
  } else {
    return (
      <SweetAlert
        title="You must be signed in!"
        onConfirm={() => redirect()}
        confirmBtnCssClass={"px-5"}
      />
    );
  }

  
};

export default ProtectedRoute;
