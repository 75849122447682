import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import { addResponseInterceptor } from "api";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ userData, children }) => {
  let [user, setUser] = React.useState(userData);
  const navigate = useNavigate();
  user = typeof user === "string" ? JSON.parse(user) : user;


  useEffect(() => {
    // Agregar el interceptor aquí
    addResponseInterceptor(navigate);
    
    // Limpieza al desmontar el componente (opcional)
    return () => {
      // Aquí podrías limpiar el interceptor si lo deseas
      // No olvides guardar el id del interceptor si decides hacerlo
    };
  }, [navigate]);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  userData: PropTypes.any,
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
