import { axios } from "./index";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/sign-up`, data, { headers: { Authorization: `${data.token}` } });
  };

  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
  };

  static Verify = (data) => {
    return axios.post(`${base}/verify`, data);
  };

  static Change = (data) => {
    return axios.post(`${base}/change-password`, data, { headers: { Authorization: `${data.token}` } });
  };

  static Me = () => {
    return axios.get(`${base}/me`);
  };

  static ListUsers = () => {
    return axios.get(`${base}/list-users`);
  };

  static ChangeuserPassword = (username, password) => {
    return axios.post(`${base}/change-user-password`, {
      username, password
    });
  };
}


let base = "auth";

export default AuthApi;
