import Axios from "axios";
import { API_SERVER } from "../config/constant";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { "Content-Type": "multipart/form-data" },
});

axios.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('user');
    if(userData) {
      config.headers.Authorization =  JSON.parse(userData).token;
    }
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    return Promise.reject(error);
  }
);
class FilesApi {
  static getbyfiles = (files) => {
    return axios.get(`${base}/${files}`);
  };

  static uploadFile = (form) => {
    return axios.post(`${base}`, form);
  };

  static getFilesList = (path) => {
    return axios.get(`${base}${path}`);
  };
  static deleteFile = (key) => {
    return axios.delete(`${base}/by-key?key=${key}`);
  };
}

let base = "files";

export default FilesApi;