const tableData = {
  columns: [
    { name: "patient_full_name", align: "left" },
    { name: "facility", align: "center" },
    { name: "patient_percentage", align: "left" },
    { name: "insurance", align: "center" }
  ]
};

export default tableData;
