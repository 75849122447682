import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Cover from "assets/images/cover-login.jpg";
import Takahami from "assets/images/curved-images/Takahami-tab.jpg";
import AuthApi from "../../../api/auth";
import { useAuth } from "../../../auth-context/auth.context";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    AuthApi.Login(formData).then(response => {
      console.log("response!!!");
      console.log(response);
      if(response.data.token) {
        setProfile(response);
      } else {
        setError(response.message);
        setLoading(false);
      }
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("There has been an error.");
      }
      setLoading(false);
    });
  };

  const handleRedirect = () => {
    return navigate("/welcome");
  };

  const setProfile = (response) => {
    console.log("!!!!");
    console.log(response);
    let user = { ...response.data };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    setLoading(false);
    return navigate("/welcome");
  };

  return (
    <BasicLayout 
      title="Welcome to Your First Login"
      description=""
      image={Cover}
    >
      {user && user.token ? (
        <Card>
          <h3 style={{ textAlign: "center" }}>You are already signed in.</h3>
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" buttonColor="info" fullWidth onClick={handleRedirect}>
              {`Let's go`}
            </SoftButton>
          </SoftBox>
        </Card>
      ) : (
        <Card>
          <SoftBox p={3} mb={1} mt={3} textAlign="center">
            <SoftTypography variant="h5" fontWeight="medium">
              Sign In:
            </SoftTypography>
            <SoftTypography variant="h6" fontWeight="light" mt={3}>
              After signing in, you will be redirected to change your password. 
              Use the temporary password sent to your email.
            </SoftTypography>
          </SoftBox>

          <SoftBox p={1}>
            <SoftBox component="form" role="form">
              <SoftBox mb={2}>
                <SoftInput type="email" placeholder="Email" value={formData.email} name="email" onChange={handleChange} />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftInput type="password" placeholder="Password" value={formData.password} name="password" onChange={handleChange} />
              </SoftBox>

              <SoftBox mt={2} mb={2} textAlign="center">
                <h6
                  style={{
                    fontSize: ".8em",
                    color: "red",
                    textAlign: "center",
                    fontWeight: 400,
                    transition: ".2s all",
                  }}
                >
                  {error}
                </h6>
              </SoftBox>
              <SoftBox mt={4} mb={1}>
                <SoftButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      )}
    </BasicLayout>
  );
}

export default Basic;
