const tableData = {
  columns: [
    { name: "first_name", align: "left" },
    { name: "last_name", align: "center" },
    { name: "role", align: "left" },
    { name: "facility", align: "left" },
    { name: "email", align: "center" },
    { name: "id", align: "center" },
    { name: "actions", align: "center" },
  ]
};

export default tableData;
