import { useState, useEffect, useCallback } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import OutlinedInput from "@mui/material/OutlinedInput";
// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftAlert from "components/SoftAlert";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";
import SweetAlert from "react-bootstrap-sweetalert";


// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "layouts/pages/users/reports/data/tableData";
import AuthApi from "api/auth";
import team2 from "assets/images/team-2.jpg";

function Reports() {
  const { columns } = tableData;

  // ComplexStatisticsCard dropdown menu state
  const [usersActiveMenu, setUsersActiveMenu] = useState(null);
  const [clickEventsMenu, setClickEventsMenu] = useState(null);
  const [purchasesMenu, setPurchasesMenu] = useState(null);
  const [likesMenu, setLikesMenu] = useState(null);
  const [ usersList, setUserList ] = useState();
  // ComplexStatisticsCard dropdown menu handlers
  const openUsersActiveMenu = (event) => setUsersActiveMenu(event.currentTarget);
  const closeUsersActiveMenu = () => setUsersActiveMenu(null);
  const openClickEventsMenu = (event) => setClickEventsMenu(event.currentTarget);
  const closeClickEventsMenu = () => setClickEventsMenu(null);
  const openPurchasesMenu = (event) => setPurchasesMenu(event.currentTarget);
  const closePurchasesMenu = () => setPurchasesMenu(null);
  const openLikesMenu = (event) => setLikesMenu(event.currentTarget);
  const closeLikesMenu = () => setLikesMenu(null);

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   bootstrap();
  }, []);
  const [formData, setFormData] = useState({
    userSelected: '',
    newPassword: ''
  });
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const bootstrap = useCallback(async () => {
    const userFinded = await AuthApi.ListUsers();
    if (userFinded.data && userFinded.data.length) {
      const userSeted = userFinded.data.map(user => ({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        role: user.role || '',
        facility: user.facility?.Facility || 'N/A',
        email: user.email || '',
        id: user.cognito_id || '',
        actions: [0,<SoftButton
          variant="button"
          color={"dark"}
          textTransform="capitalize"
          fontWeight="medium"
          onClick={() => openSetPasswordModal(user.cognito_id)}
          opacity={0.7}
        >
          Change password
        </SoftButton>]
      }));
      setUserList(() => userSeted);
    }
  }, []);
    
  const openSetPasswordModal = (user_id) => {
    handleOpen();
    setFormData({
      ...formData,
      userSelected: user_id
    });
  }

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  );
  const handleChangePass = async (username, password) => AuthApi.ChangeuserPassword(username, password);

  const handleSubmit = async(e) => {
    const passwordChanged = await handleChangePass(formData.userSelected, formData.newPassword);
    console.log("CHANGED");
    console.log(passwordChanged);
    setShowAlert(() => true);
  };

  const hideAlert = () => {
    setFormData({
      userSelected: '',
      newPassword: ''
    });
    setShowAlert(() => false);
    handleClose();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>

      
      


        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                
                
                
                
              </Grid>
            </Grid>
           
          </Grid>
        </SoftBox>
        { usersList && usersList.length && <Table columns={columns} rows={usersList} /> } 
      </SoftBox>
      <Footer />

      {showAlert && <SweetAlert
      title="Password Changed!"
      // onCancel={() => navigate("/authentication/sign-in")}
      onConfirm={() => hideAlert()}
      confirmBtnCssClass={"px-5"}
    />}
    </DashboardLayout>
  );
}

export default Reports;
