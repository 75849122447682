import Axios from "axios";
import { API_SERVER } from "../config/constant";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { "Content-Type": "application/json" },
});

axios.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('user');
    if(userData) {
      config.headers.Authorization =  JSON.parse(userData).token;
    }
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);


// Función para agregar el interceptor
const addResponseInterceptor = (navigate) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      console.log("error-----------------------------");
      console.log(error);
      // Verifica si el error es de autenticación
      if (error.response && error.response.status === 401) {
        // Maneja la expiración de la sesión
        localStorage.removeItem("user");
        navigate("/authentication/sign-in"); // Usa la función navigate aquí
      }
      return Promise.reject(error);
    }
  );
};

export { axios, addResponseInterceptor };
