// @mui material components
import { useState, useCallback, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftInput from "components/SoftInput";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import _, { groupBy } from 'underscore';

// Data n services
import PrefixApi from "../../../../api/prefix";

function Prefix() {
  const [formData, setFormData] = useState({
    'prefix': '',
  });
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [setFormData, formData]);

  const handleSubmit = useCallback(() => {
    if (!formData.prefix.trim()) {
      setError("Please enter a prefix");
      setChartData([]);
      return;
    }

    setLoading(true); // Start loading
    PrefixApi.getByPrefix(formData.prefix).then(async (response) => {
      setLoading(false); // Stop loading
      if (response && response.data) {
        if (response.data.length === 0) {
          setError("Prefix not found");
          setChartData([]);
        } else {
          // Group by Insurance and State
          const sets = groupBy(response.data, (d) => d.Insurance + "-" + d.State);
          
          // Calculate average percentage for each year and overall average
          const newChartData = Object.keys(sets).map((key) => {
            const groupedByYear = groupBy(sets[key], (item) => item.Year_received);
            const chartData = Object.keys(groupedByYear).map((year) => {
              const dataPoints = groupedByYear[year].map((item) => parseFloat(item.Year_Percentage) * 100);
              const averagePercentage = dataPoints.reduce((sum, value) => sum + value, 0) / dataPoints.length;
              return { year, averagePercentage: averagePercentage.toFixed(2) };
            });

            // Calculate the general average percentage for all years
            const allDataPoints = sets[key].map((item) => parseFloat(item.Year_Percentage) * 100);
            const generalAveragePercentage = (allDataPoints.reduce((sum, value) => sum + value, 0) / allDataPoints.length).toFixed(2);

            // Get the latest year
            const latestYear = Math.max(...Object.keys(groupedByYear).map(year => parseInt(year)));
            const latestYearData = groupedByYear[latestYear].map(item => ({
              loc: item.LOC,
              percentage: (parseFloat(item.Year_Percentage) * 100).toFixed(2)
            }));

            return {
              labels: chartData.map((data) => data.year),
              title: key,
              datasets: [
                {
                  label: key,
                  color: "dark",
                  data: chartData.map((data) => data.averagePercentage),
                },
              ],
              generalAveragePercentage,
              latestYearData,
            };
          });

          setChartData(newChartData.filter(chart => chart.datasets[0].data.length > 0));
          setError(""); // Clear any previous error messages
        }
      }
    }).catch((error) => {
      setLoading(false); // Stop loading
      if (error.message) {
        setError(error.response.data.message);
      } else {
        setError("There has been an error.");
      }
      setChartData([]);
    });
  }, [formData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SoftBox mb={2}>
                  <SoftInput type="text" placeholder="Prefix" value={formData?.prefix} name="prefix" onChange={handleChange} />
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Search"}
                </Button>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <SoftBox mb={2} color="red">
                    {error}
                  </SoftBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {chartData && chartData.length > 0 && chartData.map((currentDataChart, index) => (
                    <Grid item xs={6} key={index}>
                      <SoftBox mb={2}>
                        <VerticalBarChart
                          icon="date_range"
                          title={currentDataChart.title}
                          count={480}
                          progress={60}
                          chart={currentDataChart}
                        />
                        <SoftBox mt={2}>
                          <div>All years average: {currentDataChart.generalAveragePercentage}%</div>
                          <div>Latest Year Data:</div>
                          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                              <tr>
                                <th style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>LOC</th>
                                <th style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentDataChart.latestYearData.map((data, idx) => (
                                <tr key={idx}>
                                  <td style={{ borderRight: '1px solid #ddd', padding: '8px' }}>{data.loc}</td>
                                  <td style={{ padding: '8px' }}>{data.percentage}%</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={6}>
        <Divider />
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default Prefix;
