import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import PatientApi from "api/patient";
import AuthApi from "api/auth";
import tableData from "layouts/pages/users/reports/data/patientTableData";

function Reports() {
  const { columns } = tableData;
  const [usersList, setUserList] = useState([]);
  const [curFacility, setFacility] = useState(null);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('client');
  const [averagePercentage, setAveragePercentage] = useState(0); // Add state to hold average

  useEffect(() => {
    bootstrap(curFacility);
  }, [curFacility]);

  const bootstrap = useCallback(async (facility) => {
    const userFinded = await AuthApi.Me();
    setUser(userFinded.data);
    setUserRole(userFinded.data.role);
    if (userFinded.data.role === "client") {
      setFacility(() => userFinded.data.facility.Facility);
      setUserRole(() => userFinded.data.role);
    }
    if (facility) {
      const patiendata = await PatientApi.getpatientsList(facility);
      if (patiendata.data && patiendata.data.length) {
        // Process user data and calculate percentage as 90% instead of 0.9
        const userSeted = patiendata.data.map(user => ({
          patient_full_name: user.patient_full_name || '',
          prefix: user.prefix || '',
          facility: user.facility || '',
          insurance: user.insurance || '',
          patient_percentage: `${(parseFloat(user.patient_percentage) * 100).toFixed(2)}%` || '',
        }));
        setUserList(userSeted);

        // Calculate the average patient_percentage as a percentage
        const totalPercentage = patiendata.data.reduce((sum, user) => sum + (parseFloat(user.patient_percentage) || 0), 0);
        const average = (totalPercentage / patiendata.data.length) * 100;
        setAveragePercentage(average); // Set the calculated average
      }
    }
  }, []);

  const setcurrentFacility = useCallback(async (facility) => {
    await setFacility(facility);
  }, []);

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {/* Replace the Reviews component with average patient percentage */}
              <SoftBox>
                <SoftTypography variant="h5" fontWeight="bold">
                  Average Patient Percentage
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold" color="secondary">
                  {averagePercentage.toFixed(2)}%
                </SoftTypography>
                {/* Smaller subtitle */}
                <SoftTypography variant="caption" color="textSecondary">
                  for the top payers shown below
                </SoftTypography>
              </SoftBox>
            </Grid>
            {userRole !== "client" && (
              <Grid item xs={12} md={6}>
                <SoftBox display="flex" alignItems="center">
                  <SoftSelect
                    defaultValue={{ value: curFacility, label: curFacility }}
                    options={[
                      { value: "WRC", label: "WRC" },
                      { value: "NSR", label: "NSR" },
                      { value: "TLR", label: "TLR" },
                      { value: "THI", label: "THI" },
                      { value: "SERC", label: "SERC" },
                      { value: "PCD", label: "PCD" },
                      { value: "LHNJ", label: "LHNJ" },
                      { value: "LHNNJ", label: "LHNNJ" },
                      { value: "LHCC", label: "LHCC" },
                      { value: "LHCM", label: "LHCM" },
                      { value: "LHCO", label: "LHCO" },
                      { value: "DIFAD", label: "DIFAD" },
                      { value: "RRC", label: "RRC" },
                      { value: "EH", label: "EH" },
                    ]}
                    onChange={(e) => setcurrentFacility(e.value)}
                    size="small"
                  />
                  <SoftTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;entries per page
                  </SoftTypography>
                </SoftBox>
              </Grid>
            )}
          </Grid>

          {usersList && usersList.length && (
            <Table columns={columns} rows={usersList} />
          )}
        </SoftBox>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
